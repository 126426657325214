import * as React from 'react';
import { Link } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import { ForwardedRef } from 'react';

interface ListItemLinkProps {
  icon: JSX.Element;
  to: string;
  text: string;
}

class ListItemLink extends React.Component<ListItemLinkProps> {
  renderLink = React.forwardRef(
    (itemProps, ref: ForwardedRef<HTMLAnchorElement>) => (
      <Link to={this.props.to} {...itemProps} ref={ref} />
    ),
  );

  render() {
    const { icon, text } = this.props;
    return (
      <ListItem button component={this.renderLink}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
  }
}

export default ListItemLink;
