import HomeIcon from '@mui/icons-material/Home';
import FaceIcon from '@mui/icons-material/Face';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import EmailIcon from '@mui/icons-material/Email';
import { lazy } from 'react';

const Home = lazy(() => import('./components/Home'));
const About = lazy(() => import('./components/About'));
const Media = lazy(() => import('./components/Media'));
const Contact = lazy(() => import('./components/Contact'));
const Weddings = lazy(() => import('./components/offerings/Weddings'));
const PrivateLessons = lazy(
  () => import('./components/offerings/PrivateLessons'),
);
const GroupClasses = lazy(() => import('./components/offerings/GroupClasses'));
const SpecialEvents = lazy(
  () => import('./components/offerings/SpecialEvents'),
);

export interface Route {
  name: string;
  component: React.ComponentType;
  icon?: React.ComponentType;
}

export type Routes = Map<string, Route>;

export const routes: Routes = new Map(
  Object.entries({
    '/': {
      name: 'Home',
      component: Home,
      icon: HomeIcon,
    },
    '/about': {
      name: 'About',
      component: About,
      icon: FaceIcon,
    },
    '/media': {
      name: 'Media',
      component: Media,
      icon: CameraEnhanceIcon,
    },
    '/contact': {
      name: 'Contact',
      component: Contact,
      icon: EmailIcon,
    },
    '/weddings': {
      name: 'Wedding Dance Instruction',
      component: Weddings,
    },
    '/private-lessons': {
      name: 'Private Lessons',
      component: PrivateLessons,
    },
    '/group-classes': {
      name: 'Group Classes',
      component: GroupClasses,
    },
    '/special-events': {
      name: 'Special Events',
      component: SpecialEvents,
    },
  }),
);
